/**
 *
 * @param {string} line
 * @returns
 */
function convertLineForTV(line) {
  return line.replace('œ', 'oe').replace('Œ', 'Oe').replace('Æ', 'Ae').replace('æ', 'ae').replace('…', '...')
}

export {
  convertLineForTV,
}
